<div
  class="card standalone register-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <div class="card-header text-center">
    <h1 class="mb-0">
      {{'register.title' | translate}}
    </h1>
    <p class="mb-0 mt-2 text-muted">
      {{'register.sign-in-info' | translate}}
      <a
        *ngIf="isReady()"
        [routerLink]="[getAppConstant('PATH_LOGIN')]"
        [queryParams]="this.registerForm.get('email').value ? wrapAsObject(
            [
            getAppConstant('QP_EMAIL'), this.registerForm.get('email').value,
            getAppConstant('QP_LOGIN_HINT'), undefined,
            ]
          ) : wrapAsObject(
            [
            getAppConstant('QP_EMAIL'), null,
            getAppConstant('QP_LOGIN_HINT'), undefined,
            ]
          )"
        queryParamsHandling="merge"
      >
        {{'register.sign-in-info-link' | translate}}
      </a>
    </p>
  </div>
  <form
    *ngIf="isReady()"
    [formGroup]="registerForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="card-body">
      <ng-container *ngFor="let field of fields">
        <div
          *ngIf="field.key === 'firstName'"
          app-form-input
          field="firstName"
          autocomplete="given-name"
          label="{{'register.first-name.label' | translate}}"
          [isInvalid]="isFieldInvalid('firstName')"
          [isValid]="isFieldValid('firstName')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('firstName')"
          >{{'register.first-name.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'lastName'"
          app-form-input
          field="lastName"
          autocomplete="family-name"
          label="{{'register.last-name.label' | translate}}"
          [isInvalid]="isFieldInvalid('lastName')"
          [isValid]="isFieldValid('lastName')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('lastName')"
          >{{'register.last-name.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'professionalTitle'"
          app-form-input
          field="professionalTitle"
          autocomplete="organization-title"
          label="{{'register.professional-title.label' | translate}}"
          [isInvalid]="isFieldInvalid('professionalTitle')"
          [isValid]="isFieldValid('professionalTitle')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('professionalTitle')"
          >{{'register.professional-title.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'nickname'"
          app-form-input
          field="nickname"
          autocomplete="nickname"
          label="{{'register.nickname.label' | translate}}"
          [isInvalid]="isFieldInvalid('nickname')"
          [isValid]="isFieldValid('nickname')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('nickname')"
          >{{'register.nickname.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'preferredUsername'"
          app-form-input
          field="preferredUsername"
          autocomplete="name nickname"
          label="{{'register.display-name.label' | translate}}"
          [isInvalid]="isFieldInvalid('preferredUsername')"
          [isValid]="isFieldValid('preferredUsername')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('preferredUsername')"
          >{{'register.display-name.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'email'"
          app-form-input
          field="email"
          autocomplete="username email"
          label="{{'register.email.label' | translate}}"
          [readonly]="emailAsReadOnly"
          [isInvalid]="isFieldInvalid('email')"
          [isValid]="isFieldValid('email')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="!hasError('email','user_name_reserved')"
          >{{'register.email.error-message.required-or-invalid' | translate}}</div>
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="hasError('email','user_name_reserved')"
          >{{'register.email.error-message.reserved' | translate}}</div>
          <div
            app-form-input-feedback
            type="info"
            *ngIf="emailAsReadOnly"
          >{{'register.email.fixed-by-invitation-info' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'phoneNumber'"
          app-form-input
          field="phoneNumber"
          autocomplete="tel"
          label="{{'register.phone-number.label' | translate}}"
          [isInvalid]="isFieldInvalid('phoneNumber')"
          [isValid]="isFieldValid('phoneNumber')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('phoneNumber')"
          >{{'register.phone-number.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'password'"
          app-form-input
          field="password"
          type="password"
          autocomplete="new-password"
          label="{{'register.password.label' | translate}}"
          [isInvalid]="isFieldInvalid('password')"
          [isValid]="isFieldValid('password')"
        >
          <div
            app-form-input-feedback
            [type]="!isFieldInvalid('password') ? 'info' : 'invalid'"
          >
            <ng-container *ngIf="!isFieldInvalid('password')">{{'register.password.info' | translate}}</ng-container>
            <ng-container *ngIf="isFieldInvalid('password')">{{'register.password.error-message.required-or-invalid' | translate}}</ng-container>
          </div>
        </div>

        <div
          *ngIf="field.key === 'confirmPassword'"
          app-form-input
          field="confirmPassword"
          type="password"
          autocomplete="new-password"
          label="{{'register.confirm-password.label' | translate}}"
          [isInvalid]="isFieldInvalid('confirmPassword')"
          [isValid]="isFieldValid('confirmPassword')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('confirmPassword')"
          >{{'register.confirm-password.error-message.required-or-invalid' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'formattedAddress'"
          app-form-input
          field="formattedAddress"
          type="textarea"
          autocomplete="address"
          label="{{'register.formatted-address.label' | translate}}"
          [isInvalid]="isFieldInvalid('formattedAddress')"
          [isValid]="isFieldValid('formattedAddress')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('formattedAddress')"
          >{{'register.formatted-address.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'address-streetAddress'"
          app-form-input
          field="streetAddress"
          type="text"
          autocomplete="street-address"
          label="{{'register.street-address.label' | translate}}"
          [isInvalid]="isFieldInvalid('streetAddress')"
          [isValid]="isFieldValid('streetAddress')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('streetAddress')"
          >{{'register.street-address.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'address-postalCode'"
          app-form-input
          field="postalCode"
          type="text"
          autocomplete="postal-code"
          label="{{'register.postal-code.label' | translate}}"
          [isInvalid]="isFieldInvalid('postalCode')"
          [isValid]="isFieldValid('postalCode')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('postalCode')"
          >{{'register.postal-code.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'address-locality'"
          app-form-input
          field="locality"
          type="text"
          autocomplete="address-level2"
          label="{{'register.locality.label' | translate}}"
          [isInvalid]="isFieldInvalid('locality')"
          [isValid]="isFieldValid('locality')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('locality')"
          >{{'register.locality.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'address-region'"
          app-form-input
          field="region"
          type="text"
          autocomplete="address-level1"
          label="{{'register.region.label' | translate}}"
          [isInvalid]="isFieldInvalid('region')"
          [isValid]="isFieldValid('region')"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('region')"
          >{{'register.region.error-message.required' | translate}}</div>
        </div>

        <div
          *ngIf="field.key === 'address-country'"
          app-form-input
          field="country"
          type="select"
          autocomplete="country"
          label="{{'register.country.label' | translate}}"
          [isInvalid]="isFieldInvalid('country')"
          [isValid]="isFieldValid('country')"
          [selectOptions]="resolveCountryOptions({ label: resolveSelectCountryLabel(), value: null})"
        >
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('country')"
          >{{'register.country.error-message.required' | translate}}</div>
        </div>
        <div [class]="resolveFormInputWrapperClass()"
             *ngIf="field.key === 'agreements'"
             [ngClass]="{
                    'is-invalid': isFieldInvalid('agreements'),
                    'is-valid': isFieldValid('agreements')
                  }"
        >
          <h5 *ngIf="!!agreements">
            {{'register.agreements.title' | translate}}
          </h5>
          <ng-container *ngIf="!agreements">
            <div [class]="resolveFormInputControlWrapperClass() ">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  [id]="'agreements'"
                  [name]="'agreements'"
                  [formControlName]="'agreements'"
                  class="custom-control-input"
                  (click)="registerForm.get('agreements').markAsTouched()"
                  (keypress)="registerForm.get('agreements').markAsTouched()"
                  [ngClass]="{
                    'is-invalid': isFieldInvalid('agreements'),
                    'is-valid': isFieldValid('agreements')
                  }"
                />
                <label
                  class="custom-control-label"
                  [for]="'agreements'"
                >
                  <span [innerHTML]="'register.terms-and-conditions.copy' | translate"></span>
                </label>
                <div
                  app-form-input-feedback
                  type="invalid"
                  *ngIf="isFieldInvalid('agreements')"
                >{{'register.terms-and-conditions.error-message.required' | translate}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let agreement of agreements; let ind = index;">
            <div [class]="resolveFormInputControlWrapperClass() ">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  [id]="'agreements_' + ind"
                  [name]="'agreements_' + ind"
                  [formControlName]="'agreements_' + ind"
                  class="custom-control-input"
                  (click)="registerForm.get('agreements_' + ind).markAsTouched()"
                  (keypress)="registerForm.get('agreements' + ind).markAsTouched()"
                  [ngClass]="{
                    'is-invalid': isFieldInvalid('agreements_' + ind),
                    'is-valid': isFieldValid('agreements_' + ind)
                  }"
                />
                <label
                  class="custom-control-label"
                  [for]="'agreements_' + ind"
                >
                  <span class="agreement-label" [innerHTML] = "'register.agreements.agreement-label' | translate: { name: getAgreementNameLink(agreement) }" ></span>
                  <span *ngIf="!agreement.required" class="ml-2 badge badge-muted">{{'register.optional-label' | translate}}</span>
                </label>
              </div>
            </div>
          </ng-container>
          <div
            app-form-input-feedback
            type="invalid"
            *ngIf="isFieldInvalid('agreements') && agreements && agreements.length > 0"
          >{{'register.agreements.error-message.required' | translate}}</div>
        </div>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <span
          class="text-danger order-2 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
          [@.disabled]="disableAnimations()"
        >
          <span class="icon fa fa-fw fa-exclamation-triangle"></span>
          <span>
            {{'register.submit-error.notification' | translate}}
          </span>
        </span>
        <button
          class="btn btn-success"
          data-test-submit-button
          type="submit"
          [disabled]="!registerForm.valid"
        >
          {{'register.register-button.label' | translate}}
        </button>
        <a
          class="btn btn-link order-3 mr-auto"
          data-test-login-link
          [routerLink]="[getAppConstant('PATH_LOGIN')]"
          [queryParams]="this.registerForm.get('email').value ? wrapAsObject(
            [
            getAppConstant('QP_EMAIL'), this.registerForm.get('email').value,
            getAppConstant('QP_LOGIN_HINT'), undefined
            ]
          ) : wrapAsObject(
            [
            getAppConstant('QP_EMAIL'), null,
            getAppConstant('QP_LOGIN_HINT'), undefined,
            ]
          )"
          queryParamsHandling="merge"
        >
          {{'register.login-button.label' | translate}}
        </a>
      </div>
    </div>
  </form>
</div>

<app-duke-alert
  type="danger"
  alertClass="mb-0"
  *ngIf="hasError()"
  dismissible="false"
  data-test-submit-failed-message
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); genericError = false;"
>
  <p class="mb-0">
    {{'register.submit-error.message' | translate}}
  </p>
</app-duke-alert>
<app-duke-alert
  type="danger"
  alertClass="mb-0"
  *ngIf="consentFailed"
  dismissible="false"
  data-test-consent-failed-message
  (open)="alertHandler.onOpen($event);"
  (close)="alertHandler.onClose($event); consentFailed = false;"
>
  <p class="mb-0">
    {{'register.consent-error.message' | translate}}
  </p>
</app-duke-alert>
