<ng-template #secondaryFlows>
  <ng-container *ngIf="showSecondaryFlows()">
    <div class="secondary-flows">
      <hr />
      <div class="wrapper">
        <h2>{{'login.alternative-flow-title'|translate}}</h2>
        <ul class="list-unstyled">
          <ng-container *ngFor="let flow of getAvailableFlows()">
            <li *ngIf="isValidSecondaryFlow(flow)">
              <button
                type="button"
                [ngClass]="getFlowButtonClass(flow)"
                (click)="chooseFlow(flow);"
                [innerHTML]="getFlowButtonLabel(flow)"
              >
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>
</ng-template>

<div
  class="card standalone login-component error"
  [attr.data-test-server-error]="showServerError"
  [ngClass]="{
    'show': isReady()
  }"
  *ngIf="!!showServerError && (
      showServerError === getAppConstant('ERROR_INVALID_SESSION') ||
      showServerError === getAppConstant('ERROR_OAUTH_INVALID_REDIRECT_URI')
  )"
>
  <ng-container
    *ngIf="showServerError === getAppConstant('ERROR_OAUTH_INVALID_REDIRECT_URI')"
  >
    <div class="card-header text-center">
      <h1 class="mb-0">
        {{'login.invalid-redirect-uri-error.title'|translate}}
      </h1>
    </div>
    <div class="card-body">
      <p
        [innerHTML]="'login.invalid-redirect-uri-error.copy'|translate"
      ></p>
    </div>
  </ng-container>
  <form
    *ngIf="showServerError === getAppConstant('ERROR_INVALID_SESSION')"
    [formGroup]="errorForm"
  >
    <div class="card-header text-center">
      <h1 class="mb-0">
        {{'login.invalid-session-error.title'|translate}}
      </h1>
    </div>
    <div class="card-body">
      <p
        [innerHTML]="'login.invalid-session-error.copy'|translate"
      ></p>
      <div [class]="resolveFormInputWrapperClass() + ' mb-0'"
      >
        <div [class]="resolveFormInputControlWrapperClass()">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="continue"
              name="continue"
              formControlName="continue"
              class="custom-control-input"
              (click)="errorForm.get('continue').markAsTouched()"
              (keypress)="errorForm.get('continue').markAsTouched()"
              [ngClass]="{
                    'is-invalid': errorForm.get('continue').invalid && errorForm.get('continue').touched,
                    'is-valid': errorForm.get('continue').valid && errorForm.get('continue').touched
                  }"
            />
            <label
              class="custom-control-label"
              for="continue"
            >
              <span [innerHTML]="'login.invalid-session-error.checkbox' | translate"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          class="btn btn-secondary"
          data-test-error-continue-link
          type="button"
          [disabled]="!this.errorForm.valid"
          [routerLink]="[getAppConstant('PATH_LOGIN')]"
          [queryParams]="this.defaultForm.get('userName').value ? wrapAsObject(
                  [getAppConstant('QP_EMAIL'), this.defaultForm.get('userName').value,
                  getAppConstant('QP_LOGIN_HINT'), undefined,
                      getAppConstant('QP_ERROR'), null]
                ) : wrapAsObject(
                  [getAppConstant('QP_EMAIL'), null,
                  getAppConstant('QP_LOGIN_HINT'), undefined,
                      getAppConstant('QP_ERROR'), null]
                )"
          queryParamsHandling="merge"
        >
          {{'login.invalid-session-error.continue-label' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
<div
  class="card standalone login-component"
  [attr.data-test-is-reset-password]="isResetPassword()"
  [ngClass]="{
    'show': isReady()
  }"
  *ngIf="!showServerError || (showServerError !== getAppConstant('ERROR_INVALID_SESSION') && showServerError !== getAppConstant('ERROR_OAUTH_INVALID_REDIRECT_URI'))"
>
  <app-duke-alert
    type="danger"
    [attr.data-test-server-error]="showServerError"
    *ngIf="!!showServerError && showServerError !== getAppConstant('ERROR_INVALID_SESSION')"
    alertClass="mb-0"
    dismissible="true"
    (close)="setShowServerError(undefined)"
  >
    <p
      *ngIf="showServerError === getAppConstant('ERROR_USER_DATA_CONFLICT')"
      class="mb-0"
      [innerHTML]="'login.user-data-conflict-error'|translate"
    ></p>
    <p
      *ngIf="showServerError === getAppConstant('ERROR_INVALID_ANTI_FORGERY_TOKEN')"
      class="mb-0"
      [innerHTML]="'login.invalid-anti-forgery-token-error'|translate"
    ></p>
    <p
      *ngIf="showServerError === getAppConstant('ERROR_INSUFFICIENT_AUTHENTICATIONS')"
      [innerHTML]="'login.insufficient-authentications-error'|translate"
      class="mb-0"
    ></p>
    <p
      *ngIf="showServerError !== getAppConstant('ERROR_USER_DATA_CONFLICT') &&
        showServerError !== getAppConstant('ERROR_INVALID_ANTI_FORGERY_TOKEN') &&
        showServerError !== getAppConstant('ERROR_INVALID_SESSION') &&
        showServerError !== getAppConstant('ERROR_OAUTH_INVALID_REDIRECT_URI') &&
        showServerError !== getAppConstant('ERROR_INSUFFICIENT_AUTHENTICATIONS')"
      class="mb-0"
      [innerHTML]="'login.server-error'|translate"
    ></p>
  </app-duke-alert>
  <ng-container *ngIf="!processingDelegated">
    <div class="card-header text-center" *ngIf="isResetPassword()">
      <h1 class="mb-0">
        {{'login.title.reset-password'|translate}}
      </h1>
    </div>
    <div class="card-header text-center dropright" *ngIf="!isResetPassword()"
         #cardHeader>
      <h1>
        {{'login.title.default' | translate}}
      </h1>
      <p *ngIf="isActiveFlowStep()" class="mb-0">
        {{'login.subtitle.default' | translate: { service_name: configuration.getProperties().serviceName } }}
      </p>
      <p *ngIf="!isActiveFlowStep()" class="mb-0">
        {{'login.subtitle.username' | translate}}
      </p>
      <button
        type="button"
        id="usernameHolder"
        class="btn btn-auto btn-lg btn-block dropdown-toggle mb-0 text-truncate{{!isActiveFlowStep() ? ' show' : ''}}"
        [ngbTooltip]="('login.profile-card.tooltip' | translate)"
        (click)="first(getCurrentFlowStepTab())"
        #restartBtn
      >
        <span class="icon fa fa-fw fa-user-circle align-middle"></span> {{defaultForm.get('userName').value}}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="processingDelegated">
    <div class="card-header text-center" *ngIf="!isResetPassword()">
      <h1>
        {{'login.title.default' | translate}}
      </h1>
      <p class="mb-0">
        {{'login.processing-delegated-info'|translate}}
      </p>
    </div>
  </ng-container>
  <div class="tab-content" #tabHolder *ngIf="!processingDelegated">
    <div
      #usernameTab
      id="usernameTab"
      class="tab-pane"
      [ngClass]="{
        'active': isActiveFlowStep(),
        'show': isActiveFlowStep()
      }"
    >
      <form
        [formGroup]="defaultForm"
        (ngSubmit)="next()"
      >
        <div class="card-body">
          <div
            app-form-input
            #userName
            field="userName"
            type="text"
            size="lg"
            label="{{'login.user-name.label' | translate}}"
            autocomplete="username email"
            [isInvalid]="isFieldInvalid(defaultForm.get('userName'))"
            [isValid]="isFieldValid(defaultForm.get('userName'))"
            [readonly]="userNameAsFixed"
            container="body"
            placement="auto"
            [ngbTooltip]="userNameAsFixed ? ('login.user-name.fixed-tip' | translate) : undefined"
          >
            <div
              app-form-input-feedback
              type="invalid"
              *ngIf="isFieldInvalid(defaultForm.get('userName'))"
            >{{'login.user-name.error-message.required-or-invalid' | translate}}</div>
          </div>
          <div class="form-group clearfix" *ngIf="showRememberMe">
            <div class="custom-control custom-checkbox float-right">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                formControlName="rememberMe"
                class="custom-control-input"
              />
              <label
                class="custom-control-label"
                for="rememberMe"
              >
                {{'login.remember-me.label' | translate}}
              </label>
            </div>
          </div>
          <ng-container *ngIf="!secondaryFlowsInFooter" [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showSignInFailed()"
              [@cardFooterFeedbackTransition]
              [@.disabled]="disableAnimations()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'login.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'login.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
                </span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showSignInFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span [innerHTML] = "'login.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
            <button
              type="submit"
              class="order-1 btn btn-primary"
              data-test-next-button
              [disabled]="!defaultForm.valid"
            >
              {{'login.next-button.label' | translate}}
            </button>
            <a
              class="btn btn-link order-3 mr-auto"
              data-test-registration-link
              [routerLink]="[getAppConstant('PATH_REGISTER')]"
              [queryParams]="this.defaultForm.get('userName').value ? wrapAsObject(
                [getAppConstant('QP_EMAIL'), this.defaultForm.get('userName').value,
                getAppConstant('QP_LOGIN_HINT'), undefined,
                    getAppConstant('QP_ERROR'), null]
              ) : wrapAsObject(
                [getAppConstant('QP_EMAIL'), null,
                getAppConstant('QP_LOGIN_HINT'), undefined,
                    getAppConstant('QP_ERROR'), null]
              )"
              queryParamsHandling="merge"
            >
              {{'login.register-button.label' | translate}}
            </a>
          </div>
          <ng-container *ngIf="secondaryFlowsInFooter" [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
      </form>
    </div>
    <div
      #passwordTab
      id="passwordTab"
      class="tab-pane"
      [ngClass]="{
        'active': isActiveFlowStep(passwordStepKey),
        'show': isActiveFlowStep(passwordStepKey)
      }"
    >
      <form
        [formGroup]="isResetPassword() ? resetPasswordForm : passwordForm"
        (ngSubmit)="next()"
      >
        <div class="card-body">
          <div *ngIf="isResetPassword()">
            <ng-container *ngIf="hasSession()">
              <app-duke-alert
                type="danger"
                alertClass="static"
              >
                <h4>
                  {{'login.reset-password-error.logged-in-title' | translate}}
                </h4>
                <p className="mb-0">
                  {{'login.reset-password-error.logged-in-copy' | translate}}
                </p>
              </app-duke-alert>
            </ng-container>
            <ng-container *ngIf="!hasSession()">
              <app-duke-alert
                type="success"
                *ngIf="isActiveFlowStep('password') && isResetPassword() && emailsent"
                dismissible="true"
                (open)="alertHandler.onOpen($event);"
                (close)="alertHandler.onClose($event); emailsent = false;"
              >
                <p class="mb-0">
                  {{'login.reset-password-info.key-sent-notification' | translate}}
                </p>
              </app-duke-alert>
              <app-duke-alert
                type="danger"
                *ngIf="isActiveFlowStep('password') && isResetPassword() && emailfailed"
                dismissible="true"
                (open)="alertHandler.onOpen($event);"
                (close)="alertHandler.onClose($event); emailfailed = false;"
              >
                <p class="mb-0">
                  {{'login.reset-password-info.key-send-failed-notification' | translate}}
                </p>
              </app-duke-alert>
              <app-duke-alert
                type="info"
                alertClass="mb-0"

              >
                <p *ngIf="!showCodeInput && resetPasswordForm.get('code').valid">
                  {{'login.reset-password.login-info'|translate}}
                </p>
                <p *ngIf="!showCodeInput && !resetPasswordForm.get('code').valid">
                  {{'login.reset-password-info.key-sent-copy-without-key' | translate}}
                </p>
                <p *ngIf="showCodeInput">
                  {{'login.reset-password-info.key-sent-copy' | translate}}
                </p>
                <p *ngIf="showCodeInput || !resetPasswordForm.get('code').valid">
                  {{'login.reset-password-info.resend-key-copy' | translate}}
                </p>
                <form (ngSubmit)="sendEmail()" *ngIf="showCodeInput || invalidCodeError || !resetPasswordForm.get('code').valid">
                  <div class="clearfix">
                    <button
                      data-test-resend-reset-password-link
                      class="float-right btn btn-info"
                      [disabled]="sendEmailTimeout > 0"
                      type="submit"
                    >{{'login.reset-password-info.resend-key-button.label' | translate}}
                    </button>
                    <small class="float-right text-muted" style="clear:right;" *ngIf="sendEmailTimeout > 0">
                      <span class="icon fa fa-fw fa-ban mr-1"></span>
                      <span [innerHTML] = "'login.reset-password-info.resend-wait-copy' | translate: { timeout: sendEmailTimeout }"></span>
                    </small>
                  </div>
                </form>
              </app-duke-alert>
              <hr  *ngIf="showCodeInput || showPasswordInputsWithoutCode || resetPasswordForm.get('code').valid"/>
              <input *ngIf="!showCodeInput"
                     type="hidden"
                     name="code"
                     id="code"
                     formControlName="code"
              />
              <div
                *ngIf="showCodeInput"
                app-form-input
                #codeFieldForResetPassword
                field="code"
                label="{{'login.code.label' | translate}}"
                [isInvalid]="isFieldInvalid(resetPasswordForm.get('code'))"
                [isValid]="isFieldValid(resetPasswordForm.get('code'))"
              >
                <div
                  app-form-input-feedback
                  type="invalid"
                  *ngIf="isFieldInvalid(resetPasswordForm.get('code')) && !resetPasswordForm.get('code').value"
                >{{'login.code.error-message.required' | translate}}</div>
                <div
                  app-form-input-feedback
                  type="invalid"
                  *ngIf="hasError(resetPasswordForm.get('code')) && resetPasswordForm.get('code').value"
                >{{'login.code.error-message.invalid' | translate}}</div>
              </div>
              <ng-container *ngIf="showPasswordInputsWithoutCode || resetPasswordForm.get('code').valid">
                <div
                  app-form-input
                  #passwordForResetPassword
                  field="passwordForResetPassword"
                  type="password"
                  label="{{'login.password-for-reset-password.label' | translate}}"
                  autocomplete="new-password"
                  [isInvalid]="isFieldInvalid(resetPasswordForm.get('passwordForResetPassword'))"
                  [isValid]="isFieldValid(resetPasswordForm.get('passwordForResetPassword'))"
                >
                  <div
                    app-form-input-feedback
                    [type]="!isFieldInvalid(resetPasswordForm.get('passwordForResetPassword')) ? 'info' : 'invalid'"

                  ><ng-container *ngIf="!isFieldInvalid(resetPasswordForm.get('passwordForResetPassword'))">{{'login.password-for-reset-password.info' | translate}}</ng-container>
                    <ng-container *ngIf="isFieldInvalid(resetPasswordForm.get('passwordForResetPassword'))">{{'login.password-for-reset-password.error-message.required-or-invalid' | translate}}</ng-container>
                  </div>
                </div>
                <div
                  app-form-input
                  field="confirmPassword"
                  type="password"
                  label="{{'login.confirm-password.label' | translate}}"
                  autocomplete="new-password"
                  [isInvalid]="isFieldInvalid(resetPasswordForm.get('confirmPassword'))"
                  [isValid]="isFieldValid(resetPasswordForm.get('confirmPassword'))"
                >
                  <div
                    app-form-input-feedback
                    type="invalid"
                    *ngIf="!hasError(resetPasswordForm.get('confirmPassword'))"
                  >{{'login.confirm-password.error-message.required-or-invalid' | translate}}</div>
                </div>
              </ng-container>
              <app-duke-alert
                data-test-submit-failed-message
                type="danger"
                alertClass="mb-0"
                *ngIf="isResetPassword() && isActiveFlowStep(passwordStepKey) && hasError()"
                dismissible="true"
                (open)="alertHandler.onOpen($event);"
                (close)="alertHandler.onClose($event); genericError = undefined;"
              >
                <p class="mb-0" *ngIf="!tooManyAttemptsError && !invalidCodeError">
                  {{'login.submit-error.message.failed' | translate}}
                </p>
                <p class="mb-0" *ngIf="!tooManyAttemptsError && invalidCodeError">
                  {{'login.submit-error.message.reset_password_not_authorized' | translate}}
                </p>
                <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttempts' | translate"></p>
                <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError  && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
                <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
              </app-duke-alert>
            </ng-container>
          </div>
          <div *ngIf="!isResetPassword()">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input
              type="text"
              hidden
              name="userName"
              [value]="defaultForm.get('userName').value"
              autocomplete="username"
            />
            <div
              app-form-input
              #password
              field="password"
              type="password"
              label="{{'login.password.label' | translate}}"
              size="lg"
              autocomplete="current-password"
              [isInvalid]="isFieldInvalid(passwordForm.get('password'))"
              [isValid]="isFieldValid(passwordForm.get('password'))"
            >
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="!hasError(passwordForm.get('password'))"
              >{{'login.password.error-message.required' | translate}}</div>
              <div
                app-form-input-feedback
                type="invalid"
                *ngIf="hasError(passwordForm.get('password'))"
              >{{'login.password.error-message.invalid' | translate}}</div>
            </div>
            <app-duke-alert
              data-test-submit-failed-message
              type="danger"
              alertClass="mb-0"
              *ngIf="!isResetPassword() && isActiveFlowStep(passwordStepKey) && hasError()"
              dismissible="true"
              (open)="alertHandler.onOpen($event);"
              (close)="alertHandler.onClose($event); genericError = undefined;"
            >
              <p class="mb-0" *ngIf="!tooManyAttemptsError">
                {{'login.submit-error.message.failed' | translate}}
              </p>
              <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttempts' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
              <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
            </app-duke-alert>
            <ng-container *ngIf="!secondaryFlowsInFooter" [ngTemplateOutlet]="secondaryFlows"></ng-container>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showSignInFailed()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'login.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span [innerHTML] = "'login.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }">
                </span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showSignInFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span  [innerHTML] = "'login.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>
            <button
              *ngIf="!isLastStep() && (!isResetPassword() || (isResetPassword() && (showCodeInput || resetPasswordForm.get('code').valid)))"
              data-test-next-button
              type="submit"
              class="btn btn-primary"
              [disabled]="isResetPassword() ? !resetPasswordForm.valid : !passwordForm.valid"
            >
              {{'login.next-button.label' | translate}}
            </button>
            <button
              *ngIf="isLastStep() && (!isResetPassword() || (isResetPassword() && !hasSession() && (showCodeInput || resetPasswordForm.get('code').valid)))"
              data-test-next-button
              type="submit"
              class="btn btn-success"
              [disabled]="(isResetPassword() ? !resetPasswordForm.valid : !passwordForm.valid) || hasTooManyAttemptsTimeout()"
            >
              {{'login.login-button.label' | translate}}
            </button>
            <button
              *ngIf="isLastStep() && isResetPassword() && hasSession()"
              data-test-next-button
              type="button"
              class="btn btn-primary"
              (click)="continueFromInvalidReset()"
            >
              {{'login.reset-password-continue' | translate}}
            </button>
            <button
              *ngIf="isLastStep() && isResetPassword() && hasSession() && showCancelResetPassword()"
              data-test-cancel-reset-password
              type="button"
              class="btn btn-secondary order-3 mr-auto"
              (click)="cancelResetPassword()"
            >
              {{'login.reset-password-cancel' | translate}}
            </button>
            <button
              type="button"
              data-test-cancel-reset-password
              *ngIf="isResetPassword() && !hasSession()"
              [class]="(showCodeInput || resetPasswordForm.get('code').valid) ? 'btn btn-secondary order-3 mr-auto' : 'btn btn-primary'"
               (click)="cancelResetPassword()"
            >{{(showCodeInput || resetPasswordForm.get('code').valid) ? ('login.reset-password-cancel' | translate) : ('login.reset-password-continue' | translate)}}</button>
            <a
              data-test-forgot-password-link
              [routerLink]="[getAppConstant('PATH_FORGOT_PASSWORD')]"
              [queryParams]="wrapAsObject(
                [getAppConstant('QP_EMAIL'), this.defaultForm.get('userName').value,
                getAppConstant('QP_LOGIN_HINT'), undefined,]
              )"
              queryParamsHandling="merge"
              class="btn btn-link order-3 mr-auto"
              *ngIf="!isResetPassword()"
            >
              <span>{{'login.forgot-password-link.label' | translate}}</span>
            </a>
          </div>
          <ng-container *ngIf="!isResetPassword() && secondaryFlowsInFooter" [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
      </form>
    </div>
    <div
      #totpTab
      id="totpTab"
      class="tab-pane"
      [ngClass]="{
        'show active': isActiveFlowStep(totpStepKey)
      }"
    >
      <form
        [formGroup]="totpForm"
        (ngSubmit)="next()"
      >
        <div class="card-body">
          <div
            app-form-input
            #totp
            field="totp"
            size="lg"
            label="{{'login.totp.label' | translate}}"
            autocomplete="one-time-code"
            [isInvalid]="isFieldInvalid(totpForm.get('totp'))"
            [isValid]="isFieldValid(totpForm.get('totp'))"
          >
            <div
              app-form-input-feedback
              type="invalid"
              *ngIf="hasError(totpForm.get('totp'))"
            >{{'login.totp.error-message.incorrect' | translate}}</div>
            <div
              app-form-input-feedback
              [type]="!isFieldInvalid(totpForm.get('totp')) ? 'info' : 'invalid'"
              *ngIf="!hasError(totpForm.get('totp'))"
            ><ng-container *ngIf="isFieldInvalid(totpForm.get('totp'))">{{'login.totp.error-message.required-or-invalid' | translate}}</ng-container>
              <ng-container *ngIf="!isFieldInvalid(totpForm.get('totp'))">{{'login.totp.help-text' | translate}}</ng-container>
            </div>
          </div>
          <app-duke-alert
            data-test-submit-failed-message
            type="danger"
            alertClass="mb-0"
            *ngIf="isActiveFlowStep(totpStepKey) && hasError()"
            dismissible="true"
            (open)="alertHandler.onOpen($event);"
            (close)="alertHandler.onClose($event); genericError = undefined;"
          >
            <p class="mb-0" *ngIf="!tooManyAttemptsError">
              {{'login.submit-error.message.failed' | translate}}
            </p>
            <p class="mb-0" *ngIf="tooManyAttemptsError" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttempts' | translate"></p>
            <p class="mb-0 mt-3" *ngIf="tooManyAttemptsError && !hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfoNoTimeout' | translate"></p>
            <p class="mb-0 mt-3" *ngIf="hasTooManyAttemptsTimeout()" data-test-submit-timeout-message [innerHTML]="'login.submit-error.message.tooManyAttemptsInfo' | translate: { timeout: tooManyAttemptsTimeout }"></p>
          </app-duke-alert>
          <ng-container *ngIf="!secondaryFlowsInFooter" [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center flex-row-reverse">
            <span
              class="text-danger order-2 mr-2 text-right"
              *ngIf="showSignInFailed()"
              data-test-submit-failed-notification
            >
              <ng-container *ngIf="!hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-exclamation-triangle mr-2"></span>
                <span>
                  {{'login.submit-error.notification.failed' | translate}}
                </span>
              </ng-container>
              <ng-container *ngIf="hasTooManyAttemptsTimeout()">
                <span class="icon fa fa-fw fa-ban mr-2"></span>
                <span  [innerHTML] = "'login.submit-error.notification.tooManyAttempts' | translate: { timeout: tooManyAttemptsTimeout }"></span>
              </ng-container>
            </span>
            <span
              class="text-muted order-2 mr-2 text-right"
              *ngIf="!showSignInFailed() && hasTooManyAttemptsTimeout()"
            >
              <span class="icon fa fa-fw fa-ban mr-2"></span>
              <span  [innerHTML] = "'login.submit-timeout.notification' | translate: { timeout: tooManyAttemptsTimeout }"></span>
            </span>

            <button
              data-test-next-button
              *ngIf="!isLastStep()"
              type="submit"
              class="btn btn-primary"
              [disabled]="!totpForm.valid"
            >
              {{'login.next-button.label' | translate}}
            </button>
            <button
              data-test-next-button
              *ngIf="isLastStep()"
              type="submit"
              class="btn btn-success"
              [disabled]="!totpForm.valid || hasTooManyAttemptsTimeout()"
            >
              {{'login.login-button.label' | translate}}
            </button>
          </div>
          <ng-container *ngIf="secondaryFlowsInFooter" [ngTemplateOutlet]="secondaryFlows"></ng-container>
        </div>
      </form>
    </div>
  </div>
  <div class="card-body" *ngIf="processingDelegated">
    <div class="loader-wrapper position-relative d-block mb-2">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
