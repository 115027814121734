import {APP_BASE_HREF, LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { ConsoleLoggerService } from 'src/util/console-logger.service';
import { Logger } from 'src/util/logger';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfigureTotpComponent } from './configure-totp/configure-totp.component';
import { DukeAlertComponent } from './duke-alert/duke-alert.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormInputFeedbackComponent } from './form-input-feedback/form-input-feedback.component';
import { FormInputComponent } from './form-input/form-input.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ValidateEmailComponent } from './validate-email/validate-email.component';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoggerErrorHandler } from './LoggerErrorHandler';
import { CanDeactivateGuardService } from './can-deactivate-guard.service';
import { LocaleService } from './locale.service';
import { AlertHandler, NoConcurrentAlertsHandler } from './no-concurrent-alerts-handler';
import { LogoComponent } from './logo/logo.component';
import {ClickOutsideDirective} from './click-outside.directive';
import { LogoutComponent } from './logout/logout.component';
import { JoinComponent } from './join/join.component';
import {getAppBaseHref, initAppBaseRef} from '../util/locationUtil';
import { BsSampleComponent } from './bs-sample/bs-sample.component';
import {HomeComponent} from './home/home.component';
import {AppStateService} from './app-state.service';
import {AuthenticationGuardService} from './authentication-guard.service';
import {ManageEmailsComponent} from './manage-emails/manage-emails.component';
import {AuthenticateDeviceComponent} from './authenticate-device/authenticate-device.component';
import {CustomReuseRouteStrategy} from '../custom-reuse-route-strategy';
import { Configuration } from 'src/gen';
import {ConfigurationGuardService} from './ui-configuration/configuration-guard.service';
import {AppRoutes} from './AppRoutes';
import {InvalidApiComponent} from './invalid-api/invalid-api.component';
import {ApiVersionGuardService} from './api-version-guard.service';
import {AgreementsComponent} from './agreements/agreements.component';
import {ManageAgreementsComponent} from './manage-agreements/manage-agreements.component';
import {HttpLoaderFactory, localisationInitializer} from '../util/localeUtil';

/**
 * This is probably not how this should be handled, but could not figure out the "angular way" to initialize this correctly for
 * what ever the import section of the ngModule(...) is. This used to be included in custom HttpLoaderFactory, but that was not quite
 * right either, as this is required elsewhere as well. The appInitializerFactory below wouls seem like good place for this, but it is
 * too late.
 */
initAppBaseRef();

export function appInitializerFactory(localeService: LocaleService, translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    localisationInitializer(localeService, translate, injector).then(resolve);
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ValidateEmailComponent,
    ForgotPasswordComponent,
    EditProfileComponent,
    ViewProfileComponent,
    DukeAlertComponent,
    FormInputComponent,
    FormInputFeedbackComponent,
    ChangePasswordComponent,
    ManageEmailsComponent,
    ConfigureTotpComponent,
    PageNotFoundComponent,
    InvalidApiComponent,
    LogoComponent,
    ClickOutsideDirective,
    LogoutComponent,
    JoinComponent,
    AgreementsComponent,
    ManageAgreementsComponent,
    HomeComponent,
    BsSampleComponent,
    AuthenticateDeviceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    environment.sessionApi,
    NgbModule,
    RouterModule.forRoot(
      AppRoutes,
      { enableTracing: false,
    onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }
    ),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: Logger, useClass: ConsoleLoggerService },
    { provide: AlertHandler, useClass: NoConcurrentAlertsHandler },
    AppStateService,
    ApiVersionGuardService,
    AuthenticationGuardService,
    ConfigurationGuardService,
    CanDeactivateGuardService,
    { provide: ErrorHandler, useClass: LoggerErrorHandler },
    { provide: APP_BASE_HREF, useFactory: getAppBaseHref },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LocaleService, TranslateService, Injector],
      multi: true
    },
    {provide: RouteReuseStrategy, useClass: CustomReuseRouteStrategy},
    { provide: Configuration, useFactory: environment.confGetter },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

