/**
 * 10Duke End User Identity API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SendEmailForResetPasswordRequest { 
    /**
     * User name (login email)
     */
    userName: string;
    /**
     * The new password to require for successfully completing the \"forgot password\" process. This can be used as an additional security measure, so that if the email sent for password reset is compromized, the email alone will not be sufficient for setting the new password but the password given here must also be provided. By default, the password must be at least eight characters long, and have at least one of: small letter, capital letter, digit, special character. This default can be overridden in configuration, in which case the \"pattern\" field will reflect the updated configuration.
     */
    password?: string;
    /**
     * URI where user can navigate for resetting password. Value of this parameter must contain format argument holder {0} for the password reset code. Optionally, the value may include format argument holder {1} for email and {2} for boolean flag indicating if password was given with this request and the must same password must be given to complete password reset.
     */
    resetUri?: string;
    /**
     * Key identifying use case for the purpose of messaging
     */
    messageKey?: SendEmailForResetPasswordRequest.MessageKeyEnum;
}
export namespace SendEmailForResetPasswordRequest {
    export type MessageKeyEnum = 'ResetPassword' | 'ActivateUser';
    export const MessageKeyEnum = {
        ResetPassword: 'ResetPassword' as MessageKeyEnum,
        ActivateUser: 'ActivateUser' as MessageKeyEnum
    };
}